<template>
  <el-dialog title="查看评价"
    :visible.sync="dialogVisible"
    width="500px"
    :close-on-click-modal="false"
    custom-class="evaluation-dialog"
    :before-close="handleClose">
    <div class="content">
      <div class="rate">
        <div class="title">案件评分</div>
        <div class="detail">
          <div class="label">时效:</div>
          <el-rate v-model="info.scoreEfficiency"
            show-text
            disabled
            :texts="texts"></el-rate>
        </div>
        <div class="detail">
          <div class="label">服务态度:</div>
          <el-rate v-model="info.scoreService"
            show-text
            disabled
            :texts="texts"></el-rate>
        </div>
        <div class="detail">
          <div class="label">理赔专业性:</div>
          <el-rate v-model="info.scoreTechnique"
            show-text
            disabled
            :texts="texts"></el-rate>
        </div>
      </div>
      <div class="claim">
        <div class="title">理赔评价</div>
        <div class="label">案件指导和建议:</div>
        <el-input type="textarea"
          :rows="4"
          placeholder="请输入内容"
          disabled
          v-model="info.content">
        </el-input>
      </div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">返 回</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      texts: ['(1/5)', '(2/5)', '(3/5)', '(4/5)', '(5/5)',],
      textarea: ''
    };
  },
  created() {
  },
  props: {
    showDialog: Boolean,
    info: Object
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        }
      }
    },
  },
  computed: {
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
  },
}
</script>
<style lang="scss" scoped>
.evaluation-dialog {
  .content {
    .rate,
    .claim {
      font-size: 14px;
      .title {
        margin-bottom: 10px;
        position: relative;
        font-size: 16px;
        font-weight: bold;
        &::before {
          content: '';
          position: absolute;
          width: 5px;
          height: 100%;
          left: -8px;
          border-radius: 5px;
          background-color: #4278c9;
        }
        &::after {
          content: '';
          position: absolute;
          left: 70px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 1px;
          background-color: #ccc;
        }
      }
    }
    .rate {
      .detail {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .label {
          width: 80px;
        }
      }
    }
    .claim {
      .label {
        margin-bottom: 10px;
      }
    }
  }
}
::v-deep .evaluation-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    overflow: auto;
    padding: 24px;
  }
}
</style>
