<template>
<div>
   
  <div class="audit-process">
    <div class="title"
      v-if="isTitle">审核流程</div>
    <div class="detail">
      <div v-if="closeReason">重开原因</div>
      <el-input type="textarea"
        :rows="5"
        disabled
        style="margin-bottom: 10px"
        v-if="closeReason"
        placeholder="请输入内容"
        v-model="closeReason"></el-input>
      <div class="record"
        v-for="item,index in auditList"
        :key="index">
        <div class="line"
          v-if="index < auditList.length -1"></div>
        <div class="avatar">
          {{ item.name ? item.name.substring(0,1) : '' }}
          <i class="el-icon-check status status-success"
            v-if="item.type == '发起申请'"></i>
          <i class="el-icon-check status status-success"
            v-if="item.type == '审核通过'"></i>
          <i class="el-icon-close status status-error"
            size="18"
            v-if="item.type == '审核驳回'"></i>
          <i class="el-icon-more status status-wait"
            v-if="item.type == '待审核'"></i>
          <img src="@/assets/font/return-返回左.png"
            v-if="item.type == '已撤回'"
            class="status status-return"
            style="color:#fff"
            alt="">
        </div>
        <div class="info">
          <div class="name">{{ item.name }}</div>
          <div class="state"
            v-if="item.type">
            <span :class="{ 'text-success': item.type == '审核通过','text-error': item.type == '审核驳回','text-return': item.type == '已撤回','text-wait': item.type == '待审核', }">{{ item.type }}</span>
            <span class="time"
              v-if="item.time && item.time != 'Invalid Date'">{{ item.time }}</span>
          </div>
          <div class="comment"
            v-if="item.comment">评论：{{ item.comment }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="negotiation">
    <div class="title">协商过程</div>
    <el-table
    ref="singleTable"
    :data="negotiationList"
    style="width: 100%">
    <el-table-column
      property="userName"
      label="姓名">
    </el-table-column>
    <el-table-column
      property="roleName"
      label="角色">
    </el-table-column>
    <el-table-column
      property="createTime"
      label="时间">
    </el-table-column>
    <el-table-column
      property="advice"
      label="意见">
    </el-table-column>
    <el-table-column
      property="describe"
      label="说明">
    </el-table-column>
  </el-table>
  </div>
</div>
</template>

<script>
export default {
  props: {
    auditList: {
      type: Array,
      default: () => []
    },
    negotiationList: {
      type: Array,
      default: () => []
    },
    closeReason: {
      type: String,
      default: ''
    },
    isTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.negotiation {
  padding: 20px 10px;
  .title {
    color: #4278c9;
    margin-bottom: 10px;
    font-weight: bold;
    margin-bottom: 12px;
  }
}
.audit-process {
  padding: 20px 30px;
  background-color: #f7fafd;
  border-radius: 10px;
  .title {
    color: #4278c9;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .detail {
    .record {
      position: relative;
      display: flex;
      min-height: 140px;
      // margin-bottom: 90px;
      padding-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      .avatar {
        width: 56px;
        height: 56px;
        background: #d9e5f4;
        border: 1px solid #4278c9;
        border-radius: 50%;
        margin-right: 15px;
        position: relative;
        font-size: 24px;
        font-weight: bold;
        color: #4278c9;
        text-align: center;
        line-height: 56px;
        .status {
          position: absolute;
          left: 80%;
          transform: translateX(-50%);
          height: 15px;
          width: 15px;
          bottom: -3px;
          font-size: 12px;
          line-height: 15px;
          border-radius: 50%;
          font-weight: bold;
          color: #fff;
          border: 2px solid #fff;
        }
        .status-success {
          background: #00bc0d;
        }
        .status-error {
          background: #c94242;
        }
        .status-wait {
          background: #0080ff;
          font-size: 8px;
        }
        .status-return {
          background: #b1b1b1;
        }
        .status-keep {
          background: #0080ffff;
          font-size: 14px;
        }
      }
      .info {
        position: relative;
        flex: 1;
        .name {
          font-weight: bold;
          min-height: 16px;
        }
        .state {
          margin: 5px 0;
          .time {
            margin-left: 5px;
          }
          .text-success {
            color: #00bc0d;
            font-weight: bold;
          }
          .text-error {
            color: #c94242;
            font-weight: bold;
          }
          .text-return {
            color: #b1b1b1;
            font-weight: bold;
          }
          .text-wait {
            color: #0080ff;
            font-weight: bold;
          }
        }
        .comment {
          // position: absolute;
          // top: 45px;
          max-width: 400px;
          padding: 10px 15px;
          border-radius: 10px;
          background-color: #e9eff9;
          line-height: 20px;
        }
      }
      .line {
        position: absolute;
        left: 28px;
        transform: translateX(-50%);
        height: calc(100% - 66px);
        width: 6px;
        top: 64px;
        border-radius: 3px;
        background-color: #ccc;
      }
    }
  }
}
</style>
