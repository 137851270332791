<template>
  <el-dialog :visible="showDialog">
    <template #title>
      <span class="dialog-title">调度申请</span>
    </template>
    <template #footer>
      <div>
        <el-button @click="close">返回</el-button>
        <el-button @click="submit">提交</el-button>
      </div>
    </template>
    <el-form class="form">
      <!-- 案件调度 -->
      <div class="item">
        <div class="title">
          <span class="title-text">案件调度</span>
        </div>
        <!-- <el-form-item>
          <template #label>
            <span class="label"> 出险案件省份： </span>
          </template>
          <div class="distpicker">
            <v-distpicker only-province></v-distpicker>
          </div>
        </el-form-item>-->
        <el-form-item>
          <template #label>
            <span class="label">案件跟进人：</span>
          </template>
          <EmployeeSelect v-model="formData.newCaseFollowerId"
            @changeChecked="handleEmployeeChange"></EmployeeSelect>
        </el-form-item>
      </div>
      <!-- 调度原因 -->
      <div>
        <div class="title">
          <span class="title-text">调度原由</span>
        </div>
        <el-form-item>
          <template #label>
            <span class="label">申请案件调度的原由：</span>
          </template>
          <el-input type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="formData.dispatchReason"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { caseDispatchDispatch } from "@/api/claimData";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import VDistpicker from "v-distpicker";
export default {
  name: "SchedulingDialog",
  components: { EmployeeSelect, VDistpicker },
  props: {
    showDialog: Boolean,
    id: {
      type: [String, Number],
      default: ''
    }
  },
  watch: {
    showDialog: {
      handler(v) {
        if (!v) {
          this.formData = {
            dispatchReason: '',
            newCaseFollower: '',
            newCaseFollowerId: ''
          };
        }
      }
    }
  },
  data() {
    return {
      formData: {
        dispatchReason: '',
        newCaseFollower: '',
        newCaseFollowerId: ''
      }
    };
  },
  methods: {
    handleEmployeeChange(e) {
      this.formData.newCaseFollower = e.title;
    },
    submit() {
      caseDispatchDispatch({ ...this.formData, caseId: this.id }).then(res => {
        this.close();
        this.$emit('changeList');
      }).catch(err => {
        console.log(err);
      });
    },
    close() {
      this.$emit('update:showDialog', false);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4278c9;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  height: 50px;
}
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .distpicker-address-wrapper select {
  width: 100%;
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 400px;
}
.form {
  margin: 0 12px;
}
.dialog-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  line-height: 0px;
}
.label {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 0px;
}
.item {
  margin-bottom: 48px;
}
.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 0px;
  margin-bottom: 19px;
  .title-text {
    margin: 0 12px 0 8px;
  }
}
.title::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 18px;
  background: #4278c9;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}
.title::after {
  content: '';
  display: inline-block;
  width: 86%;
  height: 1px;
  background: #ccccccff;
  opacity: 1;
  text-align: right;
}
</style>
