<template>
  <el-dialog :visible="showDialog" append-to-body :before-close="close">
    <template #title>
      <span class="dialog-title">认领案件</span>
    </template>
    <template #footer>
      <div>
        <el-button @click="reset">重置</el-button>
        <el-button @click="submit">提交</el-button>
      </div>
    </template>
    <el-form class="form" inline label-width="100px" label-position="top">
      <!-- 案件调度 -->
      <div class="item">
        <div class="title">
          <span class="title-text"><span style="color:red">*</span>选择案件保单</span>
          <!-- <el-select v-model="value" filterable placeholder="请选择保单">
            <el-option
               v-for="item in options"
               :key="item.value"
               :label="item.label"
               :value="item.value">
            </el-option>
         </el-select> -->
              <el-autocomplete class="inline-input"
                v-model="formData.policyNo"
                :fetch-suggestions="querySearch"
                placeholder="请输入保单号"
                @select="handleSelect"
                @blur="handlePolicyBlur"></el-autocomplete>
        </div>
        <el-form-item>
          <template #label>
            <span class="label">第一保险人：</span>
          </template>
          <el-input style="width: 200px;" v-model="policyDetail.policyFirstInsured" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="label">险种名称：</span>
          </template>
          <el-input style="width: 200px;" v-model="policyDetail.policyInsuranceType" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="label">保障期限：</span>
          </template>
          <el-input style="width: 200px;" v-model="policyDetail.time" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="label">保险公司：</span>
          </template>
          <el-input style="width: 200px;" v-model="policyDetail.policyCompany" disabled></el-input>
        </el-form-item>
      </div>
      <!-- 调度原因 -->
      <div>
        <div class="title">
          <span class="title-text">选择出险的标的</span>
              <el-select v-model="formData.equipment"
                placeholder="请选择"
                filterable
                @change="handleChangeEquipment">
                <el-option v-for="(item,index) in policySubjectList"
                  :key="index"
                  :label="item.frameNo"
                  :value="item.id"></el-option>
              </el-select>
        </div>
        <el-form-item>
          <template #label>
            <span class="label">品牌：</span>
          </template>
          <el-input v-model="formData.brand" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="label">型号：</span>
          </template>
          <el-input v-model="formData.equipmentType" disabled></el-input>
        </el-form-item>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { insClaimsCaseAdopt, searchPolicyNo } from "@/api/claimData";
import {
  getPolicyDetail
} from "@/api/policy";
export default {
  name: "SchedulingDialog",
  components: { },
  props: {
    showDialog: Boolean,
    policyList: {
      type: Array,
      default: ()=> []
    },
  },
  watch: {
    showDialog: {
      handler(v) {
        if (!v) {
         this.reset()
         this.$emit('changeList');
        }
      }
    },
    policyList: {
      handler(v) {
        if (v && v.length) {
         this.reset(v[0].id)
        }else {
         this.close();
         // this.$emit('changeList');
        }
      },
      deep: true
    }
  },
  data() {
    return {
      formData: {
        policyNo: '',
         policyId: '',
        equipment: '',
        deviceTypeName: '',
        deviceType: '',
        brand: '',
        equipmentType: '',
        frameNo: '',
        curbWeight: '',
        productionDate: '',
        subjectUnknown: false,
        workTime: '',
      },
      options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        policyDetail: {},
        policySubjectList: []
    };
  },
  methods: {
    handleEmployeeChange(e) {
      this.formData.newCaseFollower = e.title;
    },
    submit() {
      if (!this.formData.policyId) {
         this.$message.error('请选择保单');
         return false;
      }
      insClaimsCaseAdopt({ ...this.formData }).then(res => {
      //   this.close();
         this.$message.success('认领成功！');
         this.$emit('changeList')
         this.$emit('update:policyList', this.policyList.filter(v => v.id != this.formData.id))
      }).catch(err => {
        console.log(err);
      });
    },
    close() {
      this.$emit('update:showDialog', false);
    },
    reset(id = '') {
      this.policyDetail = {};
      this.policySubjectList = [];
      this.formData = {
            policyNo: '',
            policyId: '',
            equipment: '',
            deviceTypeName: '',
            deviceType: '',
            brand: '',
            equipmentType: '',
            frameNo: '',
            curbWeight: '',
            productionDate: '',
            subjectUnknown: false,
            workTime: '',
            id
          };
      if (this.policyList && this.policyList[0].policyId) {
        this.formData.policyNo = this.policyList[0].policyNo;
        this.formData.policyId = this.policyList[0].policyId;
        this.handleSelect(this.policyList[0]);
      }
    },
    querySearch(queryString, cb) {
      if (queryString) {
        searchPolicyNo({
          accuratePolicyNo: queryString,
          category: this.$route.meta.policyType,
          orderType: this.$route.meta.orderType || 2,
          searchEffective: true
        }).then(res => {
          if (res.data && res.data.length) {
            cb(res.data.map(val => {
              val.value = val.policyNo;
              return val;
            }));
          } else {
            cb([]);
          }
        }).catch(err => {
          cb([]);
        });
      } else {
        cb([]);
      }
    },
    handleSelect(e) {
      getPolicyDetail({
        id: e.policyId
      }).then(res=>{
         res.data.policyFirstInsured = res.data.policyInsuredList && res.data.policyInsuredList.length ? res.data.policyInsuredList[0].playersName : '';
         res.data.policyInsuranceType = res.data.policyPlanList && res.data.policyPlanList.length ? res.data.policyPlanList[0].insuranceTypeName || res.data.policyPlanList[0].termConfigurationName : '';
         res.data.policyCompany = res.data.acceptCompanyList && res.data.acceptCompanyList.length ? res.data.acceptCompanyList[0].companyName : '';
         res.data.time = (res.data.startDate ? res.data.startDate.split(' ')[0] : '') + ' 至 ' + (res.data.endDate ? res.data.endDate.split(' ')[0] : '');
         this.policyDetail = res.data;
         this.formData.policyId = res.data.id;
         this.policySubjectList = res.data.policySubjectList || [];
         if (res.data.policySubjectList && res.data.policySubjectList.length == 1) {
          this.formData.equipment = res.data.policySubjectList[0].id;
          this.handleChangeEquipment(res.data.policySubjectList[0].id);
         }
      });
    },
    handlePolicyBlur(e) {
      this.formData.policyNo = '';
    },
    handleChangeEquipment(e) {
      let eData = this.policySubjectList.find(val=> val.id == e)
      console.log(eData);
      let data = {
        deviceTypeName: eData.deviceTypeName,
        deviceType: eData.deviceTypeName,
        brand: eData.brandName,
        equipmentType: eData.equipmentType,
        frameNo: eData.frameNo,
        curbWeight: eData.curbWeight,
        productionDate: eData.productionDate,
        subjectUnknown: false,
        workTime: eData.workTime
      };
      Object.assign(this.formData, data);
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4278c9;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  height: 50px;
}
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .distpicker-address-wrapper select {
  width: 100%;
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 400px;
}
.form {
  margin: 0 12px;
}
.dialog-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  line-height: 0px;
}
.label {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 0px;
}
.item {
  margin-bottom: 48px;
}
.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 0px;
  margin-bottom: 19px;
  .title-text {
    margin: 0 12px 0 8px;
  }
}
// .title::before {
//   content: '';
//   display: inline-block;
//   width: 6px;
//   height: 18px;
//   background: #4278c9;
//   border-radius: 4px 4px 4px 4px;
//   opacity: 1;
// }
// .title::after {
//   content: '';
//   display: inline-block;
//   width: 86%;
//   height: 1px;
//   background: #ccccccff;
//   opacity: 1;
//   text-align: right;
// }
</style>
