<template>
  <el-dialog
    title="一键报案内容"
    :visible.sync="dialogVisible"
    append-to-body
    custom-class="policy-import-dialog"
    width="888px"
    :before-close="handleClose"
  >
    <div class="statistical-box">
      <div class="statistical-box-title">
        <div class="statistical-box-title-text">
          {{
            `一键报案-${
              details.caseFromSourceType == 1 ? "小程序" : "终端"
            }（来源）`
          }}
        </div>
        <div class="statistical-box-title-item"></div>
      </div>
      <div class="statistical-box-content">
        <div>
          <div>设备名称</div>
          <span>{{ alarmData.hardwareVehicleVO.plate || "--" }}</span>
        </div>
        <div>
          <div>设备种类</div>
          <span>{{ alarmData.hardwareVehicleVO.deviceType || "--" }}</span>
        </div>
        <div>
          <div>出险时刻天气</div>
          <span>{{ alarmData.hardwareVehicleVO.weather || "--" }}</span>
        </div>
        <div>
          <div>所属车组</div>
          <span>{{ alarmData.hardwareVehicleVO.groupName || "--" }}</span>
        </div>
        <div>
          <div>驾驶员</div>
          <span>{{ alarmData.hardwareVehicleVO.driver || "--" }}</span>
        </div>
        <div>
          <div>出险时间</div>
          <span>{{ alarmData.hardwareVehicleVO.accidentTime || "--" }}</span>
        </div>
        <div>
          <div>出险当天连续作业工时</div>
          <span>{{ alarmData.hardwareVehicleVO.workTimeDay || "--" }}</span>
        </div>
        <div>
          <div>近30天日均作业工时</div>
          <span>{{
            alarmData.hardwareVehicleVO.workTimeAverageMonth || "--"
          }}</span>
        </div>
        <div>
          <div>报案定位</div>
          <span>{{ alarmData.hardwareVehicleVO.address || "--" }}</span>
        </div>
      </div>
      <div class="statistical-box-title" style="margin-bottom: 13px">
        <div class="statistical-box-title-text">报警统计</div>
        <div class="statistical-box-title-item"></div>
      </div>
      <el-table style="width: 100%" border :data="alarmData.hardwareAlarmVO">
        <el-table-column prop="date" label="序号" width="80" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="alarmName"
          label="报警名称"
          align="center"
        ></el-table-column>
        <el-table-column prop="alarmType" label="报警类型" align="center">
          <template slot-scope="scope">
            {{ scope.row.alarmType == 1 ? "安全风险" : "设备风险" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="alarmSum"
          label="报警总次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="alarmSevenSum"
          label="近7天报警次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="alarmTodaySum"
          label="出险当日报警次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="alarmInterveneSum"
          label="出险当日干预次数"
          align="center"
        ></el-table-column>
      </el-table>
      <div class="statistical-box-decoration">
        <div class="statistical-box-decoration-top">
          <div class="statistical-box-decoration-top-tab">
            <div
              :class="{
                'statistical-box-decoration-top-tab-current':
                  decorationIndex != 1,
              }"
              @click="decorationIndex = 1"
            >
              附件
            </div>
            <div
              :class="{
                'statistical-box-decoration-top-tab-current':
                  decorationIndex != 2,
              }"
              @click="decorationIndex = 2"
            >
              定位
            </div>
          </div>
          <!-- <div
            class="statistical-box-decoration-top-download"
            v-if="details.attachment && details.attachment.length && isDownload"
            @click="$emit('downloadClick')"
          >
            <img src="@/assets/images/download-icon.png" alt="" srcset="" />
            <span>下载</span>
          </div> -->
        </div>
        <div
          class="statistical-box-decoration-down"
          v-show="decorationIndex == 1"
        >
          <div class="statistical-box-decoration-down-box">
            <div class="statistical-box-decoration-down-box-title">
              <div>出险前影像资料</div>
              <div
                class="statistical-box-decoration-top-download"
                v-if="
                  alarmData.hardwareAlarmAttachVO &&
                  alarmData.hardwareAlarmAttachVO.length &&
                  isDownload
                "
                @click="downLoadClick"
              >
                <img src="@/assets/images/download-icon.png" alt="" srcset="" />
                <span>下载附件</span>
              </div>
            </div>
            <div
              v-for="(item, index) in alarmData.hardwareAlarmAttachVO"
              style="width: 100%"
              :key="index"
            >
              <div class="statistical-box-decoration-down-box-time">
                {{ item[0].createTime }}
              </div>
              <div style="display: flex">
                <el-image
                  class="statistical-box-decoration-down-content"
                  v-for="(item2, index2) in item.filter(
                    (val) => val.filePath.indexOf('.mp4') == -1
                  )"
                  :key="index2"
                  :src="item2.filePath"
                  :preview-src-list="
                    item
                      .filter((val) => val.filePath.indexOf('.mp4') == -1)
                      .map((val) => val.filePath)
                  "
                >
                </el-image>

                <DialogVideo
                  class="statistical-box-decoration-down-content"
                  :videoSrc="item2.filePath"
                  v-for="(item2, index2) in item.filter(
                    (val) => val.filePath.indexOf('.mp4') > -1
                  )"
                  :key="index2 + 999"
                ></DialogVideo>
              </div>
              <!-- <video class="statistical-box-decoration-down-content" controls :src="item" v-for="(item,index) in details.videoAttachment" :key="index + 999"></video> -->
              <img
                src="@/assets/images/zwsj-image.png"
                v-if="
                  !alarmData.hardwareAlarmAttachVO ||
                  !alarmData.hardwareAlarmAttachVO.length
                "
                style="height: 170px; margin: auto"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        <div v-show="decorationIndex == 2" style="padding:16px;" class="statistical-box-decoration-down">
          <div class="statistical-box-decoration-down-box-title" style="margin-bottom:16px">
            <div>出险时刻定位</div>
          </div>
          <div
            class="statistical-box-decoration-down" style="border:none !important;overflow: initial;height: 235px;"
            ref="container"
          ></div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="details.handleState != 1">
      <el-button @click="dialogVisible = false">返 回</el-button>
      <el-button type="primary" @click="$emit('onHandleClick',details)"
        >确认案件</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getHardwareVehicle } from "@/api/claimData";
import DialogVideo from "@/components/DialogVideo";
import { downloadZIP } from "@/utils/index";
import moment from 'dayjs';
export default {
  props: {
    showDialog: Boolean,
    details: {
      type: Object,
      default: () => {},
    },
    isDownload: {
      type: Boolean,
      default: true,
    },
  },
  components: { DialogVideo },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        } else {
          this.decorationIndex = 1;
          this.$emit("change", true);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          getHardwareVehicle({
            caseId: this.details.id,
            //  || this.details.id  "1742017339403943937"
          }).then((res) => {
            this.alarmData = res.data;
          });
        } else {
          this.dialogVisible = false;
        }
      },
    },
    decorationIndex: {
      handler(v) {
        if (v == 2) {
          this.init();
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      decorationIndex: 1,
      marker: null,
      mapall: null,
      fileList: [],
      alarmData: {
        hardwareAlarmAttachVO: [],
        hardwareAlarmVO: [],
        hardwareVehicleVO: {},
      },
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    init() {
      const that = this;
      let Lng = "";
      let Lat = "";
      if (
        this.alarmData.hardwareAlarmVO &&
        this.alarmData.hardwareAlarmVO.length
      ) {
        Lng = this.alarmData.hardwareAlarmVO[0].lon;
        Lat = this.alarmData.hardwareAlarmVO[0].lat;
      }
      var content = [
          `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>设备名称</div><div style='font-size:14px;color: #333;'>${ this.alarmData.hardwareVehicleVO.plate }</div></div>`,
          `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>报警类型</div><div style='font-size:14px;color: #333;'>${ this.alarmData.hardwareAlarmVO[0] ? this.alarmData.hardwareAlarmVO[0].alarmName : '-' }</div></div>`,
          `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>报警时间</div><div style='font-size:14px;color: #333;'>${ this.alarmData.hardwareAlarmVO[0] ? this.alarmData.hardwareAlarmVO[0].createTime : '-' }</div></div>`,
          `<div style='display:flex;width:100%;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>具体地址</div><div style='font-size:14px;color: #333;'>${ this.alarmData.hardwareAlarmVO[0] ? this.alarmData.hardwareAlarmVO[0].alarmProvince : '-' }${ this.alarmData.hardwareAlarmVO[0] ? this.alarmData.hardwareAlarmVO[0].alarmCity : '-' }${ this.alarmData.hardwareAlarmVO[0] ? this.alarmData.hardwareAlarmVO[0].alarmArea : '-' }</div></div>`
      ];

      // 创建 infoWindow 实例	
      var infoWindow = new AMap.InfoWindow({
        content: content.join(""),  //传入 dom 对象，或者 html 字符串
        offset: new AMap.Pixel(0, -32)
      });
        
      if (that.mapall) {
        that.mapall.setCenter([Lng, Lat]);
        that.mapall.setZoom(15);
        that.mapall.remove(this.marker);
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
        // 打开信息窗体
        infoWindow.open(that.mapall, [Lng, Lat]);
      } else {
        var map = new AMap.Map(that.$refs.container, {
          resizeEnable: true,
          center: [Lng, Lat],
          zoom: 15,
        });
        that.mapall = map;
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
        // 打开信息窗体
        infoWindow.open(that.mapall, [Lng, Lat]);
      }
    },
    
    // 下载文件
    downLoadClick(row) {
      let urls = []
      this.alarmData.hardwareAlarmAttachVO.map((val,ind)=>{
        val.map((val2,ind2)=> {
          urls.push({
            url: val2.filePath,
            name:  val2.filePath.split('/')[val2.filePath.split('/').length -1 ]
          });
          //  + '.' + val2.filePath.split('.')[val2.filePath.split('.').length -1 ]
          if (ind == this.alarmData.hardwareAlarmAttachVO.length -1 && ind2 == this.alarmData.hardwareAlarmAttachVO[ind].length - 1) {
            this.$nextTick(()=>{
              downloadZIP(urls, `${ this.alarmData.hardwareVehicleVO.plate }-${this.details.caseFromSourceType == 1 ? "小程序报案" : "终端报案"}-${this.alarmData.hardwareVehicleVO.accidentTime}.zip`);
            })
          }
        })
      })
    },
  },
};
</script>

<style lang="scss">
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    height: auto;
    line-height: initial;
    padding: 15px 24px;
    background: #fff;
    .el-dialog__title {
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 16px;
    padding-top: 14px;
  }
  .statistical-box {
    &-title {
      position: relative;
      padding-left: 13px;
      &-text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      &-time {
        color: #cccccc;
        font-size: 12px;
      }
    }
    &-title::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: #4278c9;
      border-radius: 3px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 24px;
      > div {
        width: 46%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        > div {
          width: 112px;
          color: #999;
          margin-bottom: 23px;
          margin-right: 16px;
          text-align: right;
        }
        span {
          flex: 1;
          color: #333333;
        }
      }
    }
    &-decoration {
      &-top {
        display: flex;
        justify-content: space-between;
        &-tab {
          display: flex;
          div {
            width: 88px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            color: #333333 !important;
            cursor: pointer;
            border-radius: 8px 8px 0px 0px;
            border: 1px solid #cccccc !important;
            border-bottom: none;
          }
          &-current {
            color: #333 !important;
            border: 1px solid #dee3e6 !important;
            background: #dee3e6 !important;;
          }
        }
        &-download {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #0080ff;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            margin-left: 2px;
            text-decoration: underline;
          }
        }
      }
      &-down {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        padding: 16px 0;
        height: 290px;
        overflow: auto;
        border-radius: 0px 8px 8px 8px;
        border: 1px solid #dee3e6 !important;
        &-content {
          width: 128px;
          height: 80px;
          margin-bottom: 8px;
          margin-left: 15px;
          border-radius: 4px;
        }
        &-box {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &-time {
            width: 153px;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #ffffff;
            padding-left: 8px;
            margin: 8px;
            margin-left: 16px;
            background: #4278c9;
          }
          &-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            padding: 0 16px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .el-table {
      margin-bottom: 22px;
      .has-gutter {
        tr {
          .el-table__cell {
            background-color: #f2f7fd;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
          .is-leaf {
            border-bottom: 1px solid #dee3e6;
          }
        }
      }
      tr {
        .el-table__cell {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
        .is-leaf {
          border-bottom: 1px solid #dee3e6;
        }
      }
    }
    .amap-info-content {
      padding: 16px 24px;
      background-color: rgba(255,255,255,.9);
      box-shadow: 0px 3px 6px 1px rgba(51,51,51,0.1);
      border-radius: 10px;
    }
    .amap-info-sharp {
      display: none;
    }
  }
}
</style>