<template>
  <div class="main">
    <span class="label">{{ label }}{{ label ? "：" : "" }}</span>
    <span>{{ content }}</span>
    <span>{{ suffix }}</span>
  </div>
</template>

<script>
export default {
  name: "DescriptionsItem",
  model: {
    event: "change",
    prop: "content",
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    content: {
      type: [Number,String],
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
//   font-size: 14px;
//   font-weight: 400;
  color: #333333;
//   min-height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  .label {
    margin-right: 6px;
  }
}
</style>
