<template>
  <index />
</template>

<script>
import index from "./index.vue"
export default {
   name: 'claimManagement3',
   components: { index },
}
</script>

<style>

</style>