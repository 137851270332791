<template>
  <el-dialog
    title="案件调度"
    :visible.sync="dialogVisible"
    append-to-body
    custom-class="policy-import-dialog"
    width="640px"
    :before-close="handleClose"
  >
    <div class="dispatch-box">
      <div class="dispatch-box-title" style="margin-top: 0">案件跟进人</div>
      <EmployeeSelect
        v-model="ruleForm.caseFollowerId"
        placeholder="请选择客户经理"
        @change="handleChangeSalesman"
      />
      <div class="dispatch-box-title">调度原因</div>
      <el-input placeholder="请输入文字" type="textarea" v-model="ruleForm.dispatchReason" rows="6"></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">返 回</el-button>
      <el-button type="primary" @click="handleDispatch"
        >确认案件</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { claimsDispatch } from "@/api/claimData";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import moment from "dayjs";
export default {
  props: {
    showDialog: Boolean,
    details: {
      type: Object,
      default: () => {},
    },
    isDownload: {
      type: Boolean,
      default: true,
    },
  },
  components: { EmployeeSelect },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        } else {
          this.$emit("change", true);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      decorationIndex: 1,
      marker: null,
      mapall: null,
      fileList: [],
      ruleForm: {
        caseFollowerName: "",
        dispatchReason: "",
        caseFollowerId: "",
      },
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleChangeSalesman(id,name) {
      this.ruleForm.caseFollowerName = name;
      this.ruleForm.caseFollowerId = id;
    },
    handleDispatch() {
      claimsDispatch({
        ...this.ruleForm,
        caseId: this.details.id
      }).then(res => {
        this.dialogVisible = false;
        this.$message.success('调度成功');
      })
    }
  },
};
</script>

<style lang="scss">
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    height: auto;
    line-height: initial;
    padding: 15px 24px;
    background: #fff;
    .el-dialog__title {
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 16px;
    padding-top: 14px;
  }
  .dispatch-box {
    padding: 0 16px;
    &-title {
      font-size: 14px;
      color: #333333;
      margin-bottom: 12px;
      margin-top: 24px;
    }
  }
}
</style>