<template>
  <el-dialog :visible="showDialog" @close="close">
    <template #title>
      <span class="dialog-title">重开案件</span>
    </template>
    <template #footer>
      <div>
        <el-button @click="resetForm">重 置</el-button>
        <el-button @click="submit">提 交</el-button>
      </div>
    </template>
    <el-form class="form" :model="formData" ref="formNode" :rules="rules">
      <div class="item">
        <el-form-item prop="reopenReason">
          <template #label>
            <span class="label">重开原因</span>
          </template>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="formData.reopenReason"
          ></el-input>
        </el-form-item>
      </div>
      <!-- 审核流程 -->
      <auditProcess :auditList="auditList" />
    </el-form>
  </el-dialog>
</template>

<script>
import { insClaimsCaseReopen } from "@/api/claimData";
import auditProcess from '@/views/claimManagement/components/auditProcess.vue';
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import dayjs from 'dayjs';
export default {
  name: "SchedulingDialog",
  components: { EmployeeSelect, auditProcess },
  props: {
    showDialog: Boolean,
    caseData: {
      type: Object,
      default: () => { }
    }
  },
  watch: {
    showDialog: {
      handler(v) {
        if (!v) {
          this.formData = {
            reopenReason: '',
          };
        } else {
          this.auditList = [{
            name: this.caseData.caseFollower,
            type: '发起审核',
            time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            comment: ''
          }, {
            name: this.caseData.adjustmentAssistant,
            type: '审批人',
            time: '',
            comment: ''
          }];
        }
      }
    }
  },
  data() {
    return {
      formData: {
        reopenReason: '',
      },
      auditList: [],
      rules: {
        reopenReason: [
          { required: true, message: '重开原因不能为空' }
        ]
      }
    };
  },
  created() {
  },
  methods: {
    handleEmployeeChange(e) {
      this.formData.newCaseFollower = e.title;
    },
    submit() {
      this.$refs['formNode'].validate((valid) => {
        if (valid) {
          insClaimsCaseReopen({ ...this.formData, id: this.caseData.id }).then(res => {
            this.close();
            this.$emit('changeList');
          }).catch(err => {
            console.log(err);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close() {
      this.$emit('update:showDialog', false);
    },
    resetForm() {
      this.$refs['formNode'].resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4278c9;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  height: 50px;
}
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .distpicker-address-wrapper select {
  width: 100%;
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 400px;
}
.form {
  margin: 0 12px;
}
.dialog-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  line-height: 0px;
}
.label {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 0px;
}
.item {
  margin-bottom: 48px;
}
.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 0px;
  margin-bottom: 19px;
  .title-text {
    margin: 0 12px 0 8px;
  }
}
.title::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 18px;
  background: #4278c9;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}
.title::after {
  content: '';
  display: inline-block;
  width: 86%;
  height: 1px;
  background: #ccccccff;
  opacity: 1;
  text-align: right;
}
</style>
